import { FeaturedProject } from '@/types/codegen-contentful'
import { FeaturedProjectBackground } from './FeaturedProjectBackground'
import { FeaturedProjectCallToAction } from './FeaturedProjectCallToAction'
import { assignBackgroundImage } from './FeaturedProjectUtils'

export interface FeaturedProjectExperimentWrapperProps {
  featuredProject: FeaturedProject
}

export const FeaturedProjectExperimentWrapper: React.FC<FeaturedProjectExperimentWrapperProps> = ({
  featuredProject,
}) => {
  const { ctaPosition, desktopBackgroundUrl, mobileBackgroundUrl, ultrawideBackgroundUrl } = featuredProject

  const backgrounds = {
    mobile: mobileBackgroundUrl as string,
    tablet: desktopBackgroundUrl as string,
    desktop: desktopBackgroundUrl as string,
    ultra: ultrawideBackgroundUrl as string,
    gradient: true,
  }

  const backgroundImage = assignBackgroundImage(backgrounds)
  const position = ctaPosition ?? 'left'

  return (
    <>
      <FeaturedProjectBackground backgroundImage={backgroundImage} />
      <FeaturedProjectCallToAction featuredProject={featuredProject} position={position} />
    </>
  )
}
