import React, { FC } from 'react'
import classNames from 'classnames'
import { BasicCarousel } from '@/molecules/BasicCarousel'
import { MediaCard } from '@/molecules/MediaCard'
import { useGuildUser } from '@/services/GuildUserService'
import { Media } from '@/utils/EpisodeUtil'

interface EpisodesHorizontalScrollerProps {
  medias: Media[]
  padding?: boolean
}

export const EpisodesHorizontalScroller: FC<EpisodesHorizontalScrollerProps> = ({ medias, padding = false }) => {
  const { guildRoles } = useGuildUser()
  const userHasEarlyAccess = Boolean(guildRoles.hasEarlyAccess)
  const hasMediaCards = Boolean(medias?.length)

  return (
    <BasicCarousel containerClassName={classNames({ 'px-4 sm:px-8 md:px-12 xl:px-16': padding })}>
      {hasMediaCards &&
        medias?.map?.((media, i) => {
          return (
            <MediaCard
              className="w-2/3 min-w-[256px] max-w-[300px] shrink-0 snap-center snap-always sm:w-2/5 lg:w-3/5"
              {...media}
              key={media.guid}
              imgPriority={i <= 4}
              includeHoverScale={false}
              userHasEarlyAccess={userHasEarlyAccess}
            />
          )
        })}
    </BasicCarousel>
  )
}
